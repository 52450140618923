.note img {
  max-width: 320px;
  border-radius: var(--border-radius);
}

.tweet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tweet div {
  width: 100%;
}

.tweet div .twitter-tweet {
  margin: 0 auto;
}

.tweet div .twitter-tweet > iframe {
  max-height: unset;
}
